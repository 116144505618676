import { Container, Flex, Spinner, VStack, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  collection,
  query,
  orderBy,
  getDocs,
  onSnapshot,
  where,
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import Post from "./components/post";
import db from "./lib/firebase";

const App = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Hook to handle the initial fetching of posts

    const q = query(collection(db, "posts"), where("archived", "!=", true), orderBy("archived"), orderBy("netVotes", "desc"));

    getDocs(q).then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPosts(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // Hook to handle the real-time updating of posts whenever there is a
    // change in the datastore (https://firebase.google.com/docs/firestore/query-data/listen#view_changes_between_snapshots)

    const q = query(collection(db, "posts"), where("archived", "!=", true), orderBy("archived"), orderBy("netVotes", "desc"));

    // eslint-disable-next-line no-unused-vars
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const _posts = [];
      querySnapshot.forEach(doc => {
        _posts.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setPosts(_posts);
    });
  }, []);

  const postRef = useRef(null);
  const scrollToPosts = () =>
    postRef.current.scrollIntoView({ behavior: "smooth" });
  const fontSizeBreakpoint = useBreakpointValue({ base: "3xl", md: "4xl" });

  return (
    <>
      <Flex direction='column' min-height='100vh' h='100vh'>
        <Hero scrollToPosts={scrollToPosts} postRef={postRef} />
        <Container flex='1'>
          <Container maxW='xl' centerContent p={2}>
            {isLoading ? (
              <Flex minH='25vh' justifyContent='center' alignItems='center'>
                <Spinner />
              </Flex>
            ) : (
              <>
                <Text
                  fontWeight={700}
                  lineHeight={1.2}
                  p={6}
                  fontSize={fontSizeBreakpoint}
                >
                  Why we love Nick
                </Text>
                <VStack spacing={8} w='100%' ref={postRef}>
                  {posts.map(post => (
                    <Post post={post} key={post.id} />
                  ))}
                </VStack>
              </>
            )}
          </Container>
        </Container>
        <Navbar />
      </Flex>
    </>
  );
};

export default App;
