import {
  Button,
  FormControl,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import db from "../lib/firebase";
import { collection, addDoc } from "firebase/firestore";

const AddNewPost = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [text, setText] = useState("");
  const [isSaving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);

    const date = new Date();

    await addDoc(collection(db, "posts"), {
      text,
      upVotesCount: 0,
      downVotesCount: 0,
      netVotes: 0,
      createdAt: date.toUTCString(),
      updatedAt: date.toUTCString(),
      archived: false,
    });

    onClose();
    setText("");
    setSaving(false);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme='white'
        borderColor='white'
        color='white'
        variant='outline'
        rounded={"full"}
        _hover={{ bg: "whiteAlpha.500" }}
      >
        Add a note
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay>
          <ModalContent m={4}>
            <ModalHeader>Add a note</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id='post-title'>
                <Textarea
                  type='post-title'
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button onClick={onClose}>Close</Button>
                <Button
                  onClick={handleSubmit}
                  colorScheme='blue'
                  disabled={!text.trim()}
                  isLoading={isSaving}
                >
                  Save
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default AddNewPost;
