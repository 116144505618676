import { Box, Container, Flex } from "@chakra-ui/react";
import React from "react";
import AddNewPost from "./add-new-post";
import ShareButton from "./share-button";

const Navbar = () => {
  return (
    <Box position='sticky' bottom={0} p={4} bg='#4B669D' zIndex={1}>
      <Container maxW='xl' centerContent>
        <Flex justifyContent='space-between' w='100%' position='sticky' top={0}>
          <ShareButton
            colorScheme='white'
            borderColor='white'
            color='white'
            variant='outline'
            rounded={"full"}
            _hover={{ bg: "whiteAlpha.500" }}
          />
          <AddNewPost />
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;
