import { IconButton } from "@chakra-ui/react";
import { FiShare } from "react-icons/fi";
import React from "react";

const ShareButton = ({ ...props }) => {
  const shareOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Why Knot Nick",
          text: `Share your shard of Nick.`,
          url: `https://whyknotnick.com`,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch(error => {
          console.error("Something went wrong sharing the site", error);
        });
    }
  };

  if (navigator.share) {
    return (
      <IconButton
        bg={props.bg}
        rounded={props.rounded}
        color={props.color}
        _hover={props._hover}
        colorScheme={props.colorScheme}
        borderColor={props.borderColor}
        variant={props.variant}
        aria-label='Share site'
        icon={<FiShare />}
        onClick={shareOnClick}
      />
    );
  }
  return <> </>;
};

export default ShareButton;
