import {
  Flex,
  Button,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

import ShareButton from "./share-button";

const Hero = ({ scrollToPosts }) => {

  return (
    <Flex
      w={"full"}
      h={"100vh"}
      minH={"100vh"}
      backgroundImage={"url(/img/hero.jpg)"}
      backgroundSize={"cover"}
      backgroundPosition={"center right;"}
      zIndex={2}
    >
      <VStack
        w={"full"}
        p={"6"}
        justify={"space-between"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={6}>
          <Text
            color={"white"}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
          >
            Why Knot Nick?
          </Text>
          <Text
            color={"white"}
            fontWeight={300}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "xl", md: "2xl" })}
          >
            Share your favorite shards of our multi-faceted groom-to-be, or love
            what others have written.
          </Text>
          <Stack direction={"row"}>
            <Button
              bg={"whiteAlpha.300"}
              rounded={"full"}
              color={"white"}
              _hover={{ bg: "whiteAlpha.500" }}
              onClick={scrollToPosts}
            >
              Show me
            </Button>
            <ShareButton
              bg={"whiteAlpha.300"}
              rounded={"full"}
              color={"white"}
              _hover={{ bg: "whiteAlpha.500" }}
            />
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
};

export default Hero;
